import React, { useEffect, useState } from 'react'
import * as s from "./main.module.css"

import { gsap } from "gsap/dist/gsap";

import { withpath } from '../utils/index.js'

import { Waypoint } from 'react-waypoint';

import Art1 from './art1/index'
import Art2 from './art2/index'
import Art3 from './art3/index'



let tl, tlback



const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  let hdistance = -100
  let hdistancetxt = -60
  let imgscale = .65

  const [anim2active, setAnim2Active] = useState(false);
  const [anim3active, setAnim3Active] = useState(false);

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
  });

	useEffect(() => {

    let imgscale = .65

    let headerimg = document.querySelectorAll(`#headerimg`);
    let prehead = document.querySelectorAll(`#prehead`);
    let head = document.querySelectorAll(`#head`);
    let intro = document.querySelectorAll(`#intro`);

    gsap.killTweensOf(headerimg,prehead,head,intro)
    gsap.set(headerimg, {opacity: 0, scale: 1.1})
    gsap.set([prehead,head,intro], {opacity: 0, translateY: 16})
    
    let htl = gsap.timeline({ paused: false });
      htl.to(headerimg, {opacity: 1, scale: 1, duration: 1.8, transformOrigin: '50% 50%', ease: `power2.out` })
      htl.to([prehead,head,intro], {opacity: 1, translateY: 0, duration: .8, stagger: .2,  ease: `power2.out` }, '<.5')


    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1delimiter = document.querySelectorAll(`#g1-delimiter`);
    let g1line1 = document.querySelectorAll(`#g1-line1`);
    let g1line2 = document.querySelectorAll(`#g1-line2`);
    let g1label1 = document.querySelectorAll(`#g1-label1`);
    let g1label2 = document.querySelectorAll(`#g1-label2`);
    let g1label3 = document.querySelectorAll(`#g1-label3`);
    let g1label4 = document.querySelectorAll(`#g1-label4`);
    let g1label5 = document.querySelectorAll(`#g1-label5`);
    let g1label6 = document.querySelectorAll(`#g1-label6`);
    let g1label7 = document.querySelectorAll(`#g1-label7`);
    let g1label8 = document.querySelectorAll(`#g1-label8`);
    let g1vlabel1 = document.querySelectorAll(`#g1-vlabel1`);
    let g1vlabel2 = document.querySelectorAll(`#g1-vlabel2`);
    let g1vlabel3 = document.querySelectorAll(`#g1-vlabel3`);
    let g1sq1 = document.querySelectorAll(`#g1-sq1`);
    let g1sq2 = document.querySelectorAll(`#g1-sq2`);
    let g1sq3 = document.querySelectorAll(`#g1-sq3`);
    let g1sq4 = document.querySelectorAll(`#g1-sq4`);
    let g1sq5 = document.querySelectorAll(`#g1-sq5`);
    let g1sq6 = document.querySelectorAll(`#g1-sq6`);
    let g1sq7 = document.querySelectorAll(`#g1-sq7`);
    let g1sqdash1 = document.querySelectorAll(`#g1-sqdash1`);
    let g1sqdash2 = document.querySelectorAll(`#g1-sqdash2`);
    let g1sqdash3 = document.querySelectorAll(`#g1-sqdash3`);
    let g1sqdash4 = document.querySelectorAll(`#g1-sqdash4`);
    let g1sqdash5 = document.querySelectorAll(`#g1-sqdash5`);
    let g1sqdash6 = document.querySelectorAll(`#g1-sqdash6`);
    let g1sqdash7 = document.querySelectorAll(`#g1-sqdash7`);
    let g1sqlabel1 = document.querySelectorAll(`#g1-sqlabel1`);
    let g1sqlabel2 = document.querySelectorAll(`#g1-sqlabel2`);
    let g1sqlabel3 = document.querySelectorAll(`#g1-sqlabel3`);
    let g1sqlabel4 = document.querySelectorAll(`#g1-sqlabel4`);
    let g1sqlabel5 = document.querySelectorAll(`#g1-sqlabel5`);
    let g1sqlabel6 = document.querySelectorAll(`#g1-sqlabel6`);
    let g1sqlabel7 = document.querySelectorAll(`#g1-sqlabel7`);
    let g1sqlabel8 = document.querySelectorAll(`#g1-sqlabel8`);
    let g1sqlabel9 = document.querySelectorAll(`#g1-sqlabel9`);
    let g1sqlabel10 = document.querySelectorAll(`#g1-sqlabel10`);
    let g1sqlabel1b = document.querySelectorAll(`#g1-sqlabel1b`); 
    let g1sqlabel2b = document.querySelectorAll(`#g1-sqlabel2b`); 
    let g1sqlabel3b = document.querySelectorAll(`#g1-sqlabel3b`); 
    let g1sqlabel10b = document.querySelectorAll(`#g1-sqlabel10b`); 
    let g1sqline1 = document.querySelectorAll(`#g1-sqline1`); 
    let g1sqline2 = document.querySelectorAll(`#g1-sqline2`); 
    let g1sqline3 = document.querySelectorAll(`#g1-sqline3`); 
    let g1sqline4 = document.querySelectorAll(`#g1-sqline4`); 
    let g1sqline5 = document.querySelectorAll(`#g1-sqline5`); 
    let g1sqline6 = document.querySelectorAll(`#g1-sqline6`); 
    let g1sqline7 = document.querySelectorAll(`#g1-sqline7`); 
    let g1base = document.querySelectorAll(`#g1-base`); 
    let g1base1 = document.querySelectorAll(`#g1-base1`); 
    let g1base2 = document.querySelectorAll(`#g1-base2`); 
    let g1handle1 = document.querySelectorAll(`#g1-handle1`);
    let g1handle2 = document.querySelectorAll(`#g1-handle2`);
    let g1handle3 = document.querySelectorAll(`#g1-handle3`);
    let g1handle4 = document.querySelectorAll(`#g1-handle4`);
    let g1handle5 = document.querySelectorAll(`#g1-handle5`);
    let g1handle6 = document.querySelectorAll(`#g1-handle6`);
    let g1handle7 = document.querySelectorAll(`#g1-handle7`);

    gsap.killTweensOf([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7])
    gsap.set([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7], {opacity: 0})
    gsap.set([g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7], {opacity: 0, transformOrigin: '100% 0'})


    
    let g2arrow1 = document.querySelectorAll(`#g2-arrow1`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2img1 = document.querySelectorAll(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
		gsap.set([g2arrow1,g2txt1,g2txt2,g2txt3], {opacity: 0})
		gsap.set(g2arrow1, {opacity: 0})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    

    

    let g3arrow1 = document.querySelectorAll(`#g3-arrow1`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3img1 = document.querySelectorAll(`#g3-img1`);

    gsap.killTweensOf([g3arrow1,g3txt1,g3txt2,g3img1])
		gsap.set([g3arrow1,g3txt1,g3txt2,g3txt3], {opacity: 0})
		gsap.set(g3arrow1, {opacity: 0})
    gsap.set(g3img1, {opacity: 0, scale: imgscale})
    

    let i1cover = document.querySelectorAll(`#imgCover1`);
    gsap.killTweensOf([i1cover])
    gsap.set([i1cover], {width: '100%'})
    

    let i2cover = document.querySelectorAll(`#imgCover2`);
    gsap.killTweensOf([i2cover])
		gsap.set([i2cover], {width: '100%'})

  },[])


	function enter1(){
    
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1delimiter = document.querySelectorAll(`#g1-delimiter`);
    let g1line1 = document.querySelectorAll(`#g1-line1`);
    let g1line2 = document.querySelectorAll(`#g1-line2`);
    let g1label1 = document.querySelectorAll(`#g1-label1`);
    let g1label2 = document.querySelectorAll(`#g1-label2`);
    let g1label3 = document.querySelectorAll(`#g1-label3`);
    let g1label4 = document.querySelectorAll(`#g1-label4`);
    let g1label5 = document.querySelectorAll(`#g1-label5`);
    let g1label6 = document.querySelectorAll(`#g1-label6`);
    let g1label7 = document.querySelectorAll(`#g1-label7`);
    let g1label8 = document.querySelectorAll(`#g1-label8`);
    let g1vlabel1 = document.querySelectorAll(`#g1-vlabel1`);
    let g1vlabel2 = document.querySelectorAll(`#g1-vlabel2`);
    let g1vlabel3 = document.querySelectorAll(`#g1-vlabel3`);
    let g1sq1 = document.querySelectorAll(`#g1-sq1`);
    let g1sq2 = document.querySelectorAll(`#g1-sq2`);
    let g1sq3 = document.querySelectorAll(`#g1-sq3`);
    let g1sq4 = document.querySelectorAll(`#g1-sq4`);
    let g1sq5 = document.querySelectorAll(`#g1-sq5`);
    let g1sq6 = document.querySelectorAll(`#g1-sq6`);
    let g1sq7 = document.querySelectorAll(`#g1-sq7`);
    let g1sqdash1 = document.querySelectorAll(`#g1-sqdash1`);
    let g1sqdash2 = document.querySelectorAll(`#g1-sqdash2`);
    let g1sqdash3 = document.querySelectorAll(`#g1-sqdash3`);
    let g1sqdash4 = document.querySelectorAll(`#g1-sqdash4`);
    let g1sqdash5 = document.querySelectorAll(`#g1-sqdash5`);
    let g1sqdash6 = document.querySelectorAll(`#g1-sqdash6`);
    let g1sqdash7 = document.querySelectorAll(`#g1-sqdash7`);
    let g1sqlabel1 = document.querySelectorAll(`#g1-sqlabel1`);
    let g1sqlabel2 = document.querySelectorAll(`#g1-sqlabel2`);
    let g1sqlabel3 = document.querySelectorAll(`#g1-sqlabel3`);
    let g1sqlabel4 = document.querySelectorAll(`#g1-sqlabel4`);
    let g1sqlabel5 = document.querySelectorAll(`#g1-sqlabel5`);
    let g1sqlabel6 = document.querySelectorAll(`#g1-sqlabel6`);
    let g1sqlabel7 = document.querySelectorAll(`#g1-sqlabel7`);
    let g1sqlabel8 = document.querySelectorAll(`#g1-sqlabel8`);
    let g1sqlabel9 = document.querySelectorAll(`#g1-sqlabel9`);
    let g1sqlabel10 = document.querySelectorAll(`#g1-sqlabel10`);
    let g1sqlabel1b = document.querySelectorAll(`#g1-sqlabel1b`); 
    let g1sqlabel2b = document.querySelectorAll(`#g1-sqlabel2b`); 
    let g1sqlabel3b = document.querySelectorAll(`#g1-sqlabel3b`); 
    let g1sqlabel10b = document.querySelectorAll(`#g1-sqlabel10b`); 
    let g1sqline1 = document.querySelectorAll(`#g1-sqline1`); 
    let g1sqline2 = document.querySelectorAll(`#g1-sqline2`); 
    let g1sqline3 = document.querySelectorAll(`#g1-sqline3`); 
    let g1sqline4 = document.querySelectorAll(`#g1-sqline4`); 
    let g1sqline5 = document.querySelectorAll(`#g1-sqline5`); 
    let g1sqline6 = document.querySelectorAll(`#g1-sqline6`); 
    let g1sqline7 = document.querySelectorAll(`#g1-sqline7`); 
    let g1base = document.querySelectorAll(`#g1-base`); 
    let g1base1 = document.querySelectorAll(`#g1-base1`); 
    let g1base2 = document.querySelectorAll(`#g1-base2`); 
    let g1handle1 = document.querySelectorAll(`#g1-handle1`);
    let g1handle2 = document.querySelectorAll(`#g1-handle2`);
    let g1handle3 = document.querySelectorAll(`#g1-handle3`);
    let g1handle4 = document.querySelectorAll(`#g1-handle4`);
    let g1handle5 = document.querySelectorAll(`#g1-handle5`);
    let g1handle6 = document.querySelectorAll(`#g1-handle6`);
    let g1handle7 = document.querySelectorAll(`#g1-handle7`);

    if(tlback) tlback.kill()
    if(tl) tl.kill()
    gsap.killTweensOf([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7])


    gsap.set([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7], {opacity: 0})
    gsap.set([g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7], {opacity: 0, transformOrigin: '50%, 100%'}) 
    


    tl = gsap.timeline({ paused: false });
      tl.set(g1base, {scaleX: 0, duration: 1, ease: `power2.out` })
      tl.to(g1base, {scaleX: 1, opacity: 1, duration: 1, ease: `power2.out` }, '<0')
      
      tl.to(g1txt1, {opacity: 1, duration: .8, ease: `power2.out` }, '<-.3')
      tl.to(g1delimiter, {opacity: 1, duration: .6, ease: `power2.out`}, '<.15')
      tl.to([g1handle1, g1txt2, g1txt3], {opacity: 1, duration: .6, stagger: .2, ease: `power2.out` },'<.15')
      tl.to(g1label1, {opacity: 1, duration: .6, ease: `power2.out` },'<.15')
      tl.to(g1base1, {opacity: 1, duration: .6, ease: `power2.out`}, '<.15')

      tl.to([g1sqline1, g1label2], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<.15')
      tl.set(g1sq1, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq1, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash1, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash1, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1sqlabel1b, g1sqlabel1], {opacity: 1, duration: .8}, '<0.3')

      tl.to([g1sqline2, g1label3], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      tl.set(g1sq2, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq2, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash2, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash2, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1sqlabel2b, g1sqlabel2], {opacity: 1, duration: .8}, '<0.3')

      tl.to([g1sqline3, g1label4], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      tl.set(g1sq3, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq3, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash3, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash3, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1sqlabel3b, g1sqlabel3], {opacity: 1, duration: .8}, '<0.3')

      tl.to([g1sqline4, g1label5], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      tl.set(g1sq4, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq4, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash4, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash4, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1handle2, g1sqlabel4, g1handle3, g1sqlabel5], {opacity: 1, duration: .8, stagger: .2}, '<0.3')

      tl.to([g1sqline5, g1label6], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      tl.set(g1sq5, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq5, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash5, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash5, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1handle4, g1sqlabel6, g1handle5, g1sqlabel7], {opacity: 1, duration: .8, stagger: .2}, '<0.3')

      tl.to([g1sqline6, g1label7], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      tl.set(g1sq6, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq6, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.set(g1sqdash6, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash6, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1handle6, g1sqlabel8], {opacity: 1, duration: .8}, '<0.3')

      tl.to([g1sqline7, g1base2, g1label8], {opacity: 1, duration: 1, stagger: .2, ease: `power2.out`}, '<-.4')
      
      tl.set(g1sq7, {scaleY: 0, transformOrigin: '50%, 100%'}, '<.2')
      tl.to(g1sq7, {opacity: 1, scaleY: 1, duration: .5, transformOrigin: '50%, 100%', ease: `power2.out`}, '<0')

      tl.set(g1sqdash7, {scaleY: 0, transformOrigin: '50%, 100%'}, '<0.4')
      tl.to(g1sqdash7, {opacity: 1, scaleY: 1, duration: .5}, '<0')
      tl.to([g1sqlabel9, g1sqlabel10b, g1sqlabel10], {opacity: 1, duration: .8, stagger: .2}, '<0.3')

      tl.to([g1handle7, g1vlabel3, g1vlabel2, g1vlabel1], {opacity: 1, duration: .6, stagger: .2, ease: `power2.out` },'<.15')

      tl.to([g1line1, g1line2, g1txt4], {opacity: 1, duration: .6, stagger: .3, ease: `power2.out` },'<.15')
	}

	function leave1(){
    let g1txt1 = document.querySelectorAll(`#g1-txt1`);
    let g1txt2 = document.querySelectorAll(`#g1-txt2`);
    let g1txt3 = document.querySelectorAll(`#g1-txt3`);
    let g1txt4 = document.querySelectorAll(`#g1-txt4`);
    let g1delimiter = document.querySelectorAll(`#g1-delimiter`);
    let g1line1 = document.querySelectorAll(`#g1-line1`);
    let g1line2 = document.querySelectorAll(`#g1-line2`);
    let g1label1 = document.querySelectorAll(`#g1-label1`);
    let g1label2 = document.querySelectorAll(`#g1-label2`);
    let g1label3 = document.querySelectorAll(`#g1-label3`);
    let g1label4 = document.querySelectorAll(`#g1-label4`);
    let g1label5 = document.querySelectorAll(`#g1-label5`);
    let g1label6 = document.querySelectorAll(`#g1-label6`);
    let g1label7 = document.querySelectorAll(`#g1-label7`);
    let g1label8 = document.querySelectorAll(`#g1-label8`);
    let g1vlabel1 = document.querySelectorAll(`#g1-vlabel1`);
    let g1vlabel2 = document.querySelectorAll(`#g1-vlabel2`);
    let g1vlabel3 = document.querySelectorAll(`#g1-vlabel3`);
    let g1sq1 = document.querySelectorAll(`#g1-sq1`);
    let g1sq2 = document.querySelectorAll(`#g1-sq2`);
    let g1sq3 = document.querySelectorAll(`#g1-sq3`);
    let g1sq4 = document.querySelectorAll(`#g1-sq4`);
    let g1sq5 = document.querySelectorAll(`#g1-sq5`);
    let g1sq6 = document.querySelectorAll(`#g1-sq6`);
    let g1sq7 = document.querySelectorAll(`#g1-sq7`);
    let g1sqdash1 = document.querySelectorAll(`#g1-sqdash1`);
    let g1sqdash2 = document.querySelectorAll(`#g1-sqdash2`);
    let g1sqdash3 = document.querySelectorAll(`#g1-sqdash3`);
    let g1sqdash4 = document.querySelectorAll(`#g1-sqdash4`);
    let g1sqdash5 = document.querySelectorAll(`#g1-sqdash5`);
    let g1sqdash6 = document.querySelectorAll(`#g1-sqdash6`);
    let g1sqdash7 = document.querySelectorAll(`#g1-sqdash7`);
    let g1sqlabel1 = document.querySelectorAll(`#g1-sqlabel1`);
    let g1sqlabel2 = document.querySelectorAll(`#g1-sqlabel2`);
    let g1sqlabel3 = document.querySelectorAll(`#g1-sqlabel3`);
    let g1sqlabel4 = document.querySelectorAll(`#g1-sqlabel4`);
    let g1sqlabel5 = document.querySelectorAll(`#g1-sqlabel5`);
    let g1sqlabel6 = document.querySelectorAll(`#g1-sqlabel6`);
    let g1sqlabel7 = document.querySelectorAll(`#g1-sqlabel7`);
    let g1sqlabel8 = document.querySelectorAll(`#g1-sqlabel8`);
    let g1sqlabel9 = document.querySelectorAll(`#g1-sqlabel9`);
    let g1sqlabel10 = document.querySelectorAll(`#g1-sqlabel10`);
    let g1sqlabel1b = document.querySelectorAll(`#g1-sqlabel1b`); 
    let g1sqlabel2b = document.querySelectorAll(`#g1-sqlabel2b`); 
    let g1sqlabel3b = document.querySelectorAll(`#g1-sqlabel3b`); 
    let g1sqlabel10b = document.querySelectorAll(`#g1-sqlabel10b`); 
    let g1sqline1 = document.querySelectorAll(`#g1-sqline1`); 
    let g1sqline2 = document.querySelectorAll(`#g1-sqline2`); 
    let g1sqline3 = document.querySelectorAll(`#g1-sqline3`); 
    let g1sqline4 = document.querySelectorAll(`#g1-sqline4`); 
    let g1sqline5 = document.querySelectorAll(`#g1-sqline5`); 
    let g1sqline6 = document.querySelectorAll(`#g1-sqline6`); 
    let g1sqline7 = document.querySelectorAll(`#g1-sqline7`); 
    let g1base = document.querySelectorAll(`#g1-base`); 
    let g1base1 = document.querySelectorAll(`#g1-base1`); 
    let g1base2 = document.querySelectorAll(`#g1-base2`); 
    let g1handle1 = document.querySelectorAll(`#g1-handle1`);
    let g1handle2 = document.querySelectorAll(`#g1-handle2`);
    let g1handle3 = document.querySelectorAll(`#g1-handle3`);
    let g1handle4 = document.querySelectorAll(`#g1-handle4`);
    let g1handle5 = document.querySelectorAll(`#g1-handle5`);
    let g1handle6 = document.querySelectorAll(`#g1-handle6`);
    let g1handle7 = document.querySelectorAll(`#g1-handle7`);
    
    if(tlback) tlback.kill()
    if(tl) tl.kill()
    gsap.killTweensOf([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7])
    
    tlback = gsap.timeline({ paused: false });
    tlback.to([g1txt1,g1txt2,g1txt3,g1txt4,g1delimiter,g1line1,g1line2,g1label1,g1label2,g1label3,g1label4,g1label5,g1label6,g1label7,g1label8,g1vlabel1,g1vlabel2,g1vlabel3,g1sq1,g1sq2,g1sq3,g1sq4,g1sq5,g1sq6,g1sq7,g1sqdash1,g1sqdash2,g1sqdash3,g1sqdash4,g1sqdash5,g1sqdash6,g1sqdash7,g1sqlabel1,g1sqlabel2,g1sqlabel3,g1sqlabel4,g1sqlabel5,g1sqlabel6,g1sqlabel7,g1sqlabel8,g1sqlabel9,g1sqlabel10,g1sqlabel1b,g1sqlabel2b,g1sqlabel3b,g1sqlabel10b,g1sqline1,g1sqline2,g1sqline3,g1sqline4,g1sqline5,g1sqline6,g1sqline7,g1base,g1base1,g1base2,g1handle1,g1handle2,g1handle3,g1handle4,g1handle5,g1handle6,g1handle7], {opacity: 0, duration: .6, ease: `power2.out` },'<.15')

  }


	function enter2(){

    setAnim2Active(true);

    let g2arrow1 = document.querySelectorAll(`#g2-arrow1`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2img1 = document.querySelectorAll(`#g2-img1`);

    gsap.killTweensOf([g2arrow1,g2txt1,g2txt2,g2txt3,g2img1])
    gsap.set(g2arrow1, {opacity: 0, translateX: hdistance})
    gsap.set(g2img1, {opacity: 0, scale: imgscale})
    gsap.set([g2txt1,g2txt2,g2txt3], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g2arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g2img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
			tl.to([g2txt1, g2txt2, g2txt3], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')
	}

	function leave2(){

    setAnim2Active(false);

    let g2arrow1 = document.querySelectorAll(`#g2-arrow1`);
    let g2txt1 = document.querySelectorAll(`#g2-txt1`);
    let g2txt2 = document.querySelectorAll(`#g2-txt2`);
    let g2txt3 = document.querySelectorAll(`#g2-txt3`);
    let g2img1 = document.querySelectorAll(`#g2-img1`);

    let tl = gsap.timeline({ paused: false });
      tl.to([g2arrow1, g2img1, g2txt1, g2txt2, g2txt3], {opacity: 0, duration: 1.8, stagger: .2, ease: `power2.out` })
    
	}


  function enter3(){

    setAnim3Active(true);
    
    let g3arrow1 = document.querySelectorAll(`#g3-arrow1`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3img1 = document.querySelectorAll(`#g3-img1`);

    gsap.killTweensOf([g3arrow1,g3txt1,g3txt2,g3txt3,g3img1])
		gsap.set([g3arrow1], {opacity: 0, translateX: hdistance})
		gsap.set(g3arrow1, {opacity: 0})
    gsap.set(g3img1, {opacity: 0, scale: imgscale})
    gsap.set([g3txt1,g3txt2,g3txt3], {opacity: 0, translateX: hdistancetxt})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(g3arrow1, {opacity: 1, translateX: 0, duration: 1.8, ease: `power2.out` })
      tl.to(g3img1, {opacity: 1, scale: 1, duration: 1, translateX: 0, translateY: 0, transformOrigin: 'bottom left', ease: `power2.out` }, '<.2')
			tl.to([g3txt1, g3txt2, g3txt3], {opacity: 1, translateX: 0, duration: .8, stagger: 0.2, ease: `power2.out`}, '<.5')
		
  }

  function leave3(){

    setAnim3Active(false);

    let g3arrow1 = document.querySelectorAll(`#g3-arrow1`);
    let g3txt1 = document.querySelectorAll(`#g3-txt1`);
    let g3txt2 = document.querySelectorAll(`#g3-txt2`);
    let g3txt3 = document.querySelectorAll(`#g3-txt3`);
    let g3img1 = document.querySelectorAll(`#g3-img1`);

    let tl = gsap.timeline({ paused: false });
      tl.to([g3arrow1, g3img1, g3txt1, g3txt1, g3txt2, g3txt2, g3txt3], {opacity: 0, translateX: 0, duration: 1.8, stagger: .15, ease: `power2.out` })
		
  }

  function enterI1(){

    let i1cover = document.querySelectorAll(`#imgCover1`);
    gsap.killTweensOf([i1cover])
    gsap.set([i1cover], {width: '100%'})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(i1cover, {translateX: '100%', duration: 1.8, ease: `power2.out` })

  }

  function leaveI1(){

  }

  function enterI2(){

    let i2cover = document.querySelectorAll(`#imgCover2`);
    gsap.killTweensOf([i2cover])
    gsap.set([i2cover], {width: '100%'})
    
    let tl = gsap.timeline({ paused: false });
      tl.to(i2cover, {translateX: '100%', duration: 1.8, ease: `power2.out` })

  }

  function leaveI2(){

  }
  

	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
			>
        <div id="headerimg" className={`${s.headerbgimg} ${s.headerbgimgDes}`} style={{backgroundImage: `url(${withpath(`/img/hero_bg.jpg`)})` }}/>
        <div id="headerimgMob" className={`${s.headerbgimg} ${s.headerbgimgMob}`} style={{backgroundImage: `url(${withpath(`/img/hero_bg_mob.jpg`)})` }}/>
			</div>
      <div className={s.headerbox}>
        <div id="prehead" className={s.prehead} dangerouslySetInnerHTML={{__html: data.prehead }} />
        <div id="head" className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
        <div id="intro" className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
        <div className={s.descLine} />
      </div>


      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
					<div data-id={0}>
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />

            <Waypoint 
                onEnter={(p) => {enter1()}} 
                onLeave={(p) => {leave1()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art1}>
                <h5 className={s.imghead}>缩小企业家性别差距的潜力</h5>
                <Art1 />
                <p className={s.imgcaption}>数据来源：环球创业观察；世界银行；波士顿咨询公司数据分析</p>
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />

            <Waypoint 
                onEnter={(p) => {enterI1()}} 
                onLeave={(p) => {leaveI1()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.img1}>
                <div className={s.imgWrap}>
                  <div id="imgCover1" className={s.imgCover}/>
                  <img src={`${withpath(`/img/a6_img1.jpg`)}`} alt="Tjin Lee and her team" />
                </div>
                <p className={s.imgcaption}>李慧晶 (Tjin Lee) 和她的团队在墨丘利营销与传播公司</p>
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />

            <Waypoint 
                onEnter={(p) => {enter2()}} 
                onLeave={(p) => {leave2()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art2}>
                <Art2 isAnimated={anim2active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={4} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={0} />

            <Waypoint 
                onEnter={(p) => {enterI2()}} 
                onLeave={(p) => {leaveI2()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.img1}>
                <div className={s.imgWrap}>
                  <div id="imgCover2" className={s.imgCover}/>
                  <img src={`${withpath(`/img/a6_img2.jpg`)}`} alt="Tjin Lee with her family" />
                </div>
                <p className={s.imgcaption}>李慧晶 (Tjin Lee) 与丈夫和孩子在印度尼西亚巴厘岛</p>
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={5} />
            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={6} />

            <Waypoint 
                onEnter={(p) => {enter3()}} 
                onLeave={(p) => {leave3()}} 
                bottomOffset={waypercent} 
                scrollableAncestor={`window`} 
                fireOnRapidScroll={false}
              >
              <div className={s.art3}>
                <Art3 isAnimated={anim3active} />
              </div>
            </Waypoint>

            <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={7} />
					</div>
				</div>

			</div>

		</section>
)}

export default Main
