// extracted by mini-css-extract-plugin
export var main = "main-module--main--1FvNJ";
export var article = "main-module--article--2jFsv";
export var headerbg = "main-module--headerbg--16AU3";
export var headerbgimg = "main-module--headerbgimg--3SwND";
export var headerbgimgMob = "main-module--headerbgimgMob--2c7pK";
export var headerbgimgDes = "main-module--headerbgimgDes--2M0U5";
export var headerbox = "main-module--headerbox--SRAzG";
export var prehead = "main-module--prehead--VhQOt";
export var h0 = "main-module--h0--NTeF_";
export var desc = "main-module--desc--1EfeX";
export var descLine = "main-module--descLine--27u8F";
export var box = "main-module--box--2G4QO";
export var tbox = "main-module--tbox--2ZzNy";
export var txt = "main-module--txt--1T3jE";
export var htxt = "main-module--htxt--2apbL";
export var art1 = "main-module--art1--VlUQi";
export var art2 = "main-module--art2--14cYR";
export var art3 = "main-module--art3--1Kgh6";
export var img1 = "main-module--img1--374aN";
export var imgWrap = "main-module--imgWrap--wJLq5";
export var imgCover = "main-module--imgCover--pv9fG";
export var imghead = "main-module--imghead--1lnP1";
export var imgcaption = "main-module--imgcaption--1SVnu";