import React from 'react'

const Art1 = () => {

  return (
    <>
      <svg className="graphDesktop" width="664px" height="260px" viewBox="0 0 664 260" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(0,0)">
          <g strokeWidth="1" strokeDasharray="2">
            <g id="g1-sqdash1" transform="translate(173.000000, 137.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-1" x="0" y="0" width="45" height="25"></rect>
            </g>
            <g id="g1-sqdash2" transform="translate(242.000000, 95.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-3" x="0" y="0" width="45" height="20"></rect>
            </g>
            <g id="g1-sqdash3" transform="translate(309.000000, 70.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-5" x="0" y="0" width="45" height="12"></rect>
            </g>
            <g id="g1-sqdash4" transform="translate(378.000000, 60.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-7" x="0" y="0" width="45" height="6"></rect>
            </g>
            <g id="g1-sqdash5" transform="translate(446.000000, 55.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-9" x="0" y="0" width="45" height="3"></rect>
            </g>
            <g id="g1-sqdash6" transform="translate(514.000000, 39.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-11" x="0" y="0" width="45" height="8"></rect>
            </g>
            <g id="g1-sqdash7" transform="translate(582.000000, 39.000000)" stroke="#B80F1F" day="3.991">
              <rect id="path-13" x="0" y="0" width="44" height="70"></rect>
            </g>
          </g>
          <g id="g1-delimiter" transform="translate(76.268000, 0.568000)">
            <path d="M20.8,108.869 C20.8,114.612761 16.1437614,119.269 10.4,119.269 C4.6562386,119.269 8.52651283e-14,114.612761 8.52651283e-14,108.869 C8.52651283e-14,103.125239 4.6562386,98.469 10.4,98.469 C13.1582515,98.469 15.8035322,99.5647111 17.7539105,101.515089 C19.7042889,103.465468 20.8,106.110749 20.8,108.869" id="Path" fill="#A3A3A3" fillRule="nonzero"></path>
            <line x1="10.4" y1="0" x2="10.4" y2="92.273" id="Path" stroke="#A3A3A3"></line>
            <line x1="10.4" y1="125.465" x2="10.4" y2="217.738" id="Path" stroke="#A3A3A3"></line>
            <polyline id="Path" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" points="9.462 105.337 13.337 109.437 9.462 112.914"></polyline>
          </g>
          <g id="g1-sqlabel1" transform="translate(189.259000, 146.788000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.8">
              <tspan x="0.245" y="7.061">0.8</tspan>
            </text>
          </g>
          <g id="g1-sqlabel2" transform="translate(256.551000, 102.446000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.6">
              <tspan x="0.245" y="7">0.6</tspan>
            </text>
          </g>
          <g id="g1-sqlabel3" transform="translate(324.346000, 72.467000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.4">
              <tspan x="0.245" y="7">0.4</tspan>
            </text>
          </g>
          <g id="g1-sqlabel4" transform="translate(431.280000, 63.271000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.245" y="7">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel5" transform="translate(431.280000, 72.612000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.2">
              <tspan x="0.245" y="7">0.2</tspan>
            </text>
          </g>
          <g id="g1-sqlabel6" transform="translate(500.509000, 58.745000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.245" y="7">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel7" transform="translate(500.509000, 68.086000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.245" y="7">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel10" transform="translate(597.599000, 70.480000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="2.3">
              <tspan x="0" y="7">2.3</tspan>
            </text>
          </g>
          <g id="g1-sqlabel9" transform="translate(566.535000, 46.845000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.3">
              <tspan x="0" y="7">0.3</tspan>
            </text>
          </g>
          <g id="g1-sqlabel8" transform="translate(528.995000, 39.378000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="0.3">
              <tspan x="0" y="7">0.3</tspan>
            </text>
          </g>
          <g id="g1-txt2" transform="translate(95.804000, 0.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <text id="$trillions">
              <tspan x="0" y="7">兆(单位：美元)</tspan>
            </text>
          </g>
          <g id="g1-label1" transform="translate(98.804000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Global-GDP-2016">
              <tspan x="-6" y="7">2016年全球各国</tspan>
              <tspan x="0" y="18">国内生产总值</tspan>
            </text>
          </g>
          <g id="g1-label2" transform="translate(181.804000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Asia-Pacific">
              <tspan x="-4" y="7">亚太地区 </tspan>
            </text>
          </g>
          <g id="g1-label3" transform="translate(247.304000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Europe">
              <tspan x="10.2495" y="7">欧洲</tspan>
            </text>
          </g>
          <g id="g1-label4" transform="translate(309.304000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="N-America">
              <tspan x="12.2205" y="7">北美</tspan>
            </text>
          </g>
          <g id="g1-label5" transform="translate(390.304000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Latin-America-and-Ca">
              <tspan x="-8" y="7">拉丁美洲和</tspan>
              <tspan x="-8" y="18">加勒比地区</tspan>
            </text>
          </g>
          <g id="g1-vlabel1" transform="translate(635.804000, 63.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Upper-bound">
              <tspan x="0" y="13">上界</tspan>
            </text>
          </g>
          <g id="g1-vlabel2" transform="translate(635.804000, 140.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Lower-bound">
              <tspan x="0" y="13">下界</tspan>
            </text>
          </g>
          <g id="g1-vlabel3" transform="translate(635.804000, 199.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="74.7">
              <tspan x="0" y="7">74.7</tspan>
            </text>
          </g>
          <g id="g1-txt3" transform="translate(159.804000, 199.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="74.7">
              <tspan x="0" y="7">74.7</tspan>
            </text>
          </g>
          <g id="g1-label6" transform="translate(454.804000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Africa">
              <tspan x="10.499" y="7">非洲</tspan>
            </text>
          </g>
          <g id="g1-label7" transform="translate(511.304000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Middle-East-and-Nort">
              <tspan x="0.4945" y="7">中东和北非</tspan>
            </text>
          </g>
          <g id="g1-label8" transform="translate(576.304000, 228.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal" line-spacing="10">
            <text id="Implied-GDP-with-clo">
              <tspan x="-7.7475" y="7">企业家差距消除后的</tspan>
              <tspan x="-4" y="19">隐含国内生产总值</tspan>
            </text>
          </g>
          <line x1="93.093" y1="217.036" x2="638.447" y2="217.036" id="g1-base" stroke="#757474" strokeWidth="2"></line>
          <g strokeDasharray="1.8">
            <line x1="150.096" y1="189.811" x2="173.039" y2="189.811" id="g1-sqline1" stroke="#A3A3A3" day="4"></line>
            <line x1="218.006" y1="136.895" x2="240.949" y2="136.895" id="g1-sqline2" stroke="#A3A3A3" day="4"></line>
            <line x1="286.528" y1="95.812" x2="309.471" y2="95.812" id="g1-sqline3" stroke="#A3A3A3" day="4"></line>
            <line x1="354.009" y1="70.782" x2="376.952" y2="70.782" id="g1-sqline4" stroke="#A3A3A3" day="4"></line>
            <line x1="422.836" y1="60.466" x2="445.779" y2="60.466" id="g1-sqline5" stroke="#A3A3A3" day="4"></line>
            <line x1="491.052" y1="55.894" x2="513.995" y2="55.894" id="g1-sqline6" stroke="#A3A3A3" day="4"></line>
            <line x1="558.656" y1="39.41" x2="581.599" y2="39.41" id="g1-sqline7" stroke="#A3A3A3" day="4"></line>
          </g>
          <polyline id="g1-line1" stroke="#A3A3A3" points="127.051 185.834 127.051 20.388 286.524 20.388"></polyline>
          <g id="g1-line2" transform="translate(434.085000, 20.388000)">
            <polyline id="Path" stroke="#A3A3A3" points="169.476 13.108 169.476 0 -1.70530257e-13 0"></polyline>
            <polygon id="Path" transform="translate(10, 0)" fill="#A3A3A3" fillRule="nonzero" points="159.476 8.871 164.013 8.871 161.745 11.547 159.476 14.226 157.207 11.547 154.938 8.868"></polygon>
          </g>
          <g id="g1-txt1" transform="translate(0.000000, 88.000000)" fill="#000000" fontFamily="Univers" fontSize="13" fontWeight="bold">
            <text id="Potential-GDP-Increa">
              <tspan x="0" y="12">国内生产总值</tspan>
              <tspan x="0" y="28">增长潜力</tspan>
            </text>
          </g>
          <g id="g1-txt4" transform="translate(270.000000, 10.000000)" fill="#B80F1F" fontFamily="Univers" fontSize="15" fontWeight="bold">
            <text id="+$2.5-trilion-$5-tri">
              <tspan x="21" y="9">+2.5兆美元-5兆美元</tspan>
              <tspan x="55.1405" y="25">+3%-6%</tspan>
            </text>
          </g>
          <g transform="translate(0,0)">
            <g id="g1-sq1" transform="translate(173.000000, 162.000000)" fill="#B80F1F">
              <rect id="Rectangle" x="0" y="0" width="45" height="28"></rect>
            </g>
            <g id="g1-sq2" transform="translate(242.000000, 115.000000)" fill="#B80F1F">
              <rect id="Rectangle" x="0" y="0" width="45" height="22"></rect>
            </g>
            <g id="g1-sq3" transform="translate(309.000000, 82.000000)" fill="#B80F1F">
              <rect id="Rectangle-4" x="0" y="0" width="45" height="14"></rect>
            </g>
            <g id="g1-sq4" transform="translate(378.000000, 66.000000)" fill="#B80F1F">
              <rect id="Rectangle-7" x="0" y="0" width="45" height="5"></rect>
            </g>
            <g id="g1-sq5" transform="translate(446.000000, 58.000000)" fill="#B80F1F">
              <rect id="Rectangle-8" x="0" y="0" width="45" height="3"></rect>
            </g>
            <g id="g1-sq6" transform="translate(514.000000, 47.000000)" fill="#B80F1F">
              <rect id="Rectangle-10" x="0" y="0" width="45" height="9"></rect>
            </g>
            <g id="g1-sq7" transform="translate(582.000000, 109.000000)" fill="#B80F1F">
              <rect id="Rectangle-12" x="0" y="0" width="44" height="81"></rect>
            </g>
          </g>
          <g id="g1-base1" transform="translate(102.965111, 190.000000)">
            <polygon id="Rectangle" fill="#A3A3A3" points="1.03488934 -1.77635684e-15 45.0348893 -1.77635684e-15 45.0348893 3.6658241 1.03488934 19.591"></polygon>
            <polygon id="Rectangle-14" fill="#A3A3A3" transform="translate(23.034889, 18.018000) scale(-1, -1) translate(-23.034889, -18.018000) " points="1.03488934 9 45.0348893 9 45.0348893 11.1108241 1.03488934 27.036"></polygon>
            <line x1="46.2938893" y1="3.35728359" x2="0" y2="20.0466463" id="Path-2" stroke="#4B4A4B"></line>
            <line x1="46.2938893" y1="8.35728359" x2="0" y2="25.0466463" id="Path-2" stroke="#4B4A4B"></line>
          </g>
          <g id="g1-base2" transform="translate(580.965111, 190.000000)">
            <polygon id="Rectangle" fill="#A3A3A3" points="1.03488934 -1.77635684e-15 45.0348893 -1.77635684e-15 45.0348893 3.6658241 1.03488934 19.591"></polygon>
            <polygon id="Rectangle-14" fill="#A3A3A3" transform="translate(23.034889, 18.018000) scale(-1, -1) translate(-23.034889, -18.018000) " points="1.03488934 9 45.0348893 9 45.0348893 11.1108241 1.03488934 27.036"></polygon>
            <line x1="46.2938893" y1="3.35728359" x2="0" y2="20.0466463" id="Path-2" stroke="#4B4A4B"></line>
            <line x1="46.2938893" y1="8.35728359" x2="0" y2="25.0466463" id="Path-2" stroke="#4B4A4B"></line>
          </g>
        </g>
        <g id="handles" transform="translate(148.000000, 49.000000)">
            <g id="g1-handle1" transform="translate(0.000000, 153.000000)">
                <line x1="2.27373675e-13" y1="1.5" x2="6.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="6.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle7" transform="translate(478.000000, 153.000000)">
                <line x1="2.27373675e-13" y1="1.5" x2="6.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="6.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle6" transform="translate(411.000000, 0.000000)">
                <line x1="0.5" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle3" transform="translate(275.000000, 20.000000)">
                <polyline fill="transparent" id="Path-3" stroke="#4B4A4B" points="0.767046064 0.771 2.5 0.771 2.5 7.5 6.49811656 7.5"></polyline>
                <circle id="Oval" fill="#4B4A4B" cx="6.5" cy="7.5" r="1.5"></circle>
            </g>
            <g id="g1-handle2" transform="translate(275.000000, 14.000000)">
                <polyline fill="transparent" id="Path-3" stroke="#4B4A4B" points="0.767046064 0.271 2.5 0.271 2.5 3.5 6.49811656 3.5"></polyline>
                <circle id="Oval" fill="#4B4A4B" cx="6.5" cy="3.5" r="1.5"></circle>
            </g>
            <g id="g1-handle5" transform="translate(343.000000, 10.000000)">
                <polyline fill="transparent" id="Path-3" stroke="#4B4A4B" points="0.767046064 0.5 2.5 0.5 2.5 13.5 6.49811656 13.5"></polyline>
                <circle id="Oval" fill="#4B4A4B" cx="7.5" cy="13.5" r="1.5"></circle>
            </g>
            <g id="g1-handle4" transform="translate(343.000000, 7.000000)">
                <polyline fill="transparent" id="Path-3" stroke="#4B4A4B" points="0.767046064 0.5 4.5 0.5 4.5 6.5 6.49811656 6.5"></polyline>
                <circle id="Oval" fill="#4B4A4B" cx="7.5" cy="6.5" r="1.5"></circle>
            </g>
        </g>
        <g id="additional" transform="translate(189.259000, 83.788000)" fill="#FFFFFF" fillRule="nonzero" fontFamily="Univers" fontSize="9" fontWeight="normal">
            <g id="g1-sqlabel1b" transform="translate(0.000000, 88.000000)">
                <text id="0.9">
                    <tspan x="0.245" y="7.061">0.9</tspan>
                </text>
            </g>
            <g id="g1-sqlabel2b" transform="translate(68.000000, 38.000000)">
                <text id="0.7">
                    <tspan x="0.245" y="7.061">0.7</tspan>
                </text>
            </g>
            <g id="g1-sqlabel3b" transform="translate(135.000000, 0.000000)">
                <text id="0.4">
                    <tspan x="0.245" y="7.061">0.4</tspan>
                </text>
            </g>
            <g id="g1-sqlabel10b" transform="translate(408.000000, 62.061000)">
                <text id="2.6">
                    <tspan x="0.245" y="7">2.6</tspan>
                </text>
            </g>
        </g>
      </svg>

      <svg className="graphMobile" width="366px" height="482px" viewBox="0 0 366 482" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g strokeDasharray="2">
            <g id="g1-sqdash1" transform="translate(58.965111, 227.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x1" x="0.0348893373" y="0" width="26" height="25"></rect>
            </g>
            <g id="g1-sqdash2" transform="translate(98.965111, 185.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x3" x="0.0348893373" y="0" width="26" height="20"></rect>
            </g>
            <g id="g1-sqdash3" transform="translate(137.965111, 160.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x5" x="0.0348893373" y="0" width="26" height="12"></rect>
            </g>
            <g id="g1-sqdash4" transform="translate(177.965111, 150.000000)" stroke="#B80F1F" strokeWidth="1">
              <rect id="path-x7" x="0.0348893373" y="0" width="26" height="6"></rect>
            </g>
            <g id="g1-sqdash5" transform="translate(216.965111, 145.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x9" x="0.0348893373" y="0" width="26" height="3"></rect>
            </g>
            <g id="g1-sqdash6" transform="translate(255.965111, 129.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x11" x="0.0348893373" y="0" width="26" height="8"></rect>
            </g>
            <g id="g1-sqdash7" transform="translate(294.965111, 129.000000)" stroke="#B80F1F" day="3.991" strokeWidth="1">
              <rect id="path-x13" x="0.0348893373" y="0" width="26" height="70"></rect>
            </g>
          </g>
          <g id="g1-delimiter" transform="translate(180.500000, 40.000000) rotate(-270.000000) translate(-170.500000, -40.000000) translate(160.000000, -128.000000)">
            <path d="M20.8,167.869 C20.8,173.612761 16.1437614,178.269 10.4,178.269 C4.6562386,178.269 1.13686838e-13,173.612761 1.13686838e-13,167.869 C1.13686838e-13,162.125239 4.6562386,157.469 10.4,157.469 C13.1582515,157.469 15.8035322,158.564711 17.7539105,160.515089 C19.7042889,162.465468 20.8,165.110749 20.8,167.869" id="Path" fill="#A3A3A3" fillRule="nonzero"></path>
            <line x1="10" y1="0" x2="10.4" y2="151.273" id="Path" stroke="#A3A3A3"></line>
            <line x1="10.4" y1="184.465" x2="10" y2="335" id="Path" stroke="#A3A3A3"></line>
            <polyline id="Path" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" points="9.462 164.337 13.337 168.437 9.462 171.914"></polyline>
          </g>
          <g id="g1-sqlabel1" transform="translate(64.259000, 234.788000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.8">
              <tspan x="0.16" y="9.061">0.8</tspan>
            </text>
          </g>
          <g id="g1-sqlabel2" transform="translate(103.551000, 190.446000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.6">
              <tspan x="0.16" y="9">0.6</tspan>
            </text>
          </g>
          <g id="g1-sqlabel3" transform="translate(142.346000, 160.467000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.4">
              <tspan x="0.16" y="9">0.4</tspan>
            </text>
          </g>
          <g id="g1-sqlabel4" transform="translate(182.280000, 130.271000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.16" y="9">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel5" transform="translate(182.280000, 172.612000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.2">
              <tspan x="0.16" y="9">0.2</tspan>
            </text>
          </g>
          <g id="g1-sqlabel6" transform="translate(220.509000, 123.745000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.16" y="9">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel7" transform="translate(220.509000, 162.086000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.1">
              <tspan x="0.16" y="9">0.1</tspan>
            </text>
          </g>
          <g id="g1-sqlabel10" transform="translate(299.599000, 159.480000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="2.3">
              <tspan x="0" y="9">2.3</tspan>
            </text>
          </g>
          <g id="g1-sqlabel9" transform="translate(260.535000, 149.845000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.3">
              <tspan x="0" y="9">0.3</tspan>
            </text>
          </g>
          <g id="g1-sqlabel8" transform="translate(260.995000, 108.378000)" fill="#B80F1F" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="0.3">
              <tspan x="0" y="9">0.3</tspan>
            </text>
          </g>
          <g id="g1-txt2" transform="translate(0.804000, 52.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <text id="$trillions">
              <tspan x="0" y="9">$兆(单位：美元)</tspan>
            </text>
          </g>
          <g id="g1-label1" transform="translate(24.000000, 356.000000) rotate(-90.000000) translate(-30.000000, -365.000000) translate(-18.000000, 360.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="10">
            <text id="Global-GDP-2016">
              <tspan x="0" y="9">2016年全球各国</tspan>
              <tspan x="13" y="23">国内生产总值</tspan>
            </text>
          </g>
          <g id="g1-label2" transform="translate(70.000000, 332.000000) rotate(-90.000000) translate(-70.000000, -350.000000) translate(37.000000, 345.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="10">
            <text id="Asia-Pacific">
              <tspan x="0.5" y="9">亚太地区</tspan>
            </text>
          </g>
          <g id="g1-label3" transform="translate(111.500000, 320.500000) rotate(-90.000000) translate(-111.500000, -337.500000) translate(91.000000, 331.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Europe">
              <tspan x="0.332" y="9">欧洲</tspan>
            </text>
          </g>
          <g id="g1-label4" transform="translate(150.500000, 310.500000) rotate(-90.000000) translate(-150.500000, -347.500000) translate(120.000000, 341.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="N-America">
              <tspan x="1.088" y="9">北美</tspan>
            </text>
          </g>
          <g id="g1-label5" transform="translate(189.500000, 356.500000) rotate(-90.000000) translate(-189.500000, -398.500000) translate(108.000000, 392.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Latin-America-and-Ca">
              <tspan x="1.352" y="9">拉丁美洲和加勒比地区</tspan>
            </text>
          </g>
          <g id="g1-vlabel1" transform="translate(328.804000, 150.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Upper-bound">
              <tspan x="0" y="18">上界</tspan>
            </text>
          </g>
          <g id="g1-vlabel2" transform="translate(328.804000, 224.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Lower-bound">
              <tspan x="0" y="18">下界</tspan>
            </text>
          </g>
          <g id="g1-vlabel3" transform="translate(328.804000, 289.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="74.7">
              <tspan x="0" y="9">74.7</tspan>
            </text>
          </g>
          <g id="g1-txt3" transform="translate(52.804000, 289.591000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="74.7">
              <tspan x="0" y="9">74.7</tspan>
            </text>
          </g>
          <g id="g1-label6" transform="translate(230.000000, 324.500000) rotate(-90.000000) translate(-230.000000, -333.500000) translate(213.000000, 327.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Africa">
              <tspan x="0.664" y="9">非洲</tspan>
            </text>
          </g>
          <g id="g1-label7" transform="translate(267.500000, 296.500000) rotate(-90.000000) translate(-265.500000, -399.500000) translate(183.000000, 393.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Middle-East-and-Nort">
              <tspan x="1.384" y="9">中东和北非</tspan>
            </text>
          </g>
          <g id="g1-label8" transform="translate(310.000000, 356.000000) rotate(-90.000000) translate(-307.000000, -387.000000) translate(237.000000, 374.000000)" fill="#4B4A4B" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal" line-spacing="13">
            <text id="Implied-GDP-with-clo">
              <tspan x="0.656" y="9">企业家差距消除后的</tspan>
              <tspan x="12" y="22">隐含国内生产总值</tspan>
            </text>
          </g>
          <line x1="1" y1="307.5" x2="340.907" y2="307.5" id="g1-base" stroke="#757474" strokeWidth="2"></line>
          <g strokeDasharray="1.2">
            <line x1="45.9651107" y1="279.811" x2="58.9651107" y2="279.811" id="g1-sqline1" stroke="#A3A3A3" day="4"></line>
            <line x1="84.9651107" y1="226.895" x2="98.9651107" y2="226.895" id="g1-sqline2" stroke="#A3A3A3" day="4"></line>
            <line x1="124.965111" y1="185.812" x2="137.965111" y2="185.812" id="g1-sqline3" stroke="#A3A3A3" day="4"></line>
            <line x1="163.965111" y1="160.782" x2="176.965111" y2="160.782" id="g1-sqline4" stroke="#A3A3A3" day="4"></line>
            <line x1="203.965111" y1="150.466" x2="216.965111" y2="150.466" id="g1-sqline5" stroke="#A3A3A3" day="4"></line>
            <line x1="242.965111" y1="145.894" x2="255.965111" y2="145.894" id="g1-sqline6" stroke="#A3A3A3" day="4"></line>
            <line x1="281.965111" y1="129.41" x2="294.965111" y2="129.41" id="g1-sqline7" stroke="#A3A3A3" day="4"></line>
          </g>
          <polyline id="g1-line1" stroke="#A3A3A3" points="33.051 275.834 33.051 77.834 96.051 77.834"></polyline>
          <g id="g1-line2" transform="translate(226.085000, 77.388000)">
            <polyline id="Path" stroke="#A3A3A3" points="81.476 46.108 81.476 0.108 20 0.108"></polyline>
            <polygon transform="translate(30,0)" id="Path" fill="#A3A3A3" fillRule="nonzero" points="51.476 41.871 56.013 41.871 53.745 44.547 51.476 47.226 49.207 44.547 46.938 41.868"></polygon>
          </g>
          <g id="g1-txt1" transform="translate(72.000000, 1.000000)" fill="#000000" fontFamily="Univers" fontSize="15" fontWeight="bold">
            <text id="Potential-GDP-Increa">
              <tspan x="32.472" y="15">国内生产总值增长潜力</tspan>
            </text>
          </g>
          <g id="g1-txt4" transform="translate(95.000000, 73.000000)" fill="#B80F1F" fontFamily="Univers" fontSize="15" fontWeight="bold" line-spacing="16">
            <text id="+$2.5-trilion-$5-tri">
              <tspan x="9.0075" y="11">+2.5兆美元-5兆美元</tspan>
              <tspan x="45.7775" y="27">+3%-6%</tspan>
            </text>
          </g>
          <g id="g1-sq1" transform="translate(58.965111, 252.000000)" fill="#B80F1F">
            <rect id="Rectangle" x="0.0348893373" y="0" width="26" height="28"></rect>
          </g>
          <g id="g1-sq2" transform="translate(98.965111, 205.000000)" fill="#B80F1F">
            <rect id="Rectangle" x="0.0348893373" y="0" width="26" height="22"></rect>
          </g>
          <g id="g1-sq3" transform="translate(137.965111, 172.000000)" fill="#B80F1F">
            <rect id="Rectangle-4" x="0.0348893373" y="0" width="26" height="14"></rect>
          </g>
          <g id="g1-sq4" transform="translate(177.965111, 156.000000)" fill="#B80F1F">
            <rect id="Rectangle-7" x="0.0348893373" y="0" width="26" height="5"></rect>
          </g>
          <g id="g1-sq5" transform="translate(216.965111, 148.000000)" fill="#B80F1F">
            <rect id="Rectangle-8" x="0.0348893373" y="0" width="26" height="3"></rect>
          </g>
          <g id="g1-sq6" transform="translate(255.965111, 137.000000)" fill="#B80F1F">
            <rect id="Rectangle-10" x="0.0348893373" y="0" width="26" height="9"></rect>
          </g>
          <g id="g1-sq7" transform="translate(294.965111, 199.000000)" fill="#B80F1F">
            <rect id="Rectangle-12" x="0.0348893373" y="0" width="26" height="81"></rect>
          </g>
          <g id="g1-base1" transform="translate(18.965111, 280.000000)">
            <polygon id="Rectangle" fill="#A3A3A3" points="1.03488934 0 26.0348893 0 26.0348893 3.74235526 1.03488934 20"></polygon>
            <polygon id="Rectangle-14" fill="#A3A3A3" transform="translate(13.534889, 18.000000) scale(-1, -1) translate(-13.534889, -18.000000) " points="1.03488934 9 26.0348893 9 26.0348893 11.1066109 1.03488934 27"></polygon>
            <line x1="27.0348893" y1="3" x2="0.0348893373" y2="20" id="Path-2" stroke="#4B4A4B"></line>
            <line x1="27.0348893" y1="8" x2="0.0348893373" y2="25" id="Path-2" stroke="#4B4A4B"></line>
          </g>
          <g id="g1-base2" transform="translate(293.965111, 280.000000)">
            <polygon id="Rectangle" fill="#A3A3A3" points="1.03488934 0 27.0348893 0 27.0348893 3.74235526 1.03488934 20"></polygon>
            <polygon id="Rectangle-14" fill="#A3A3A3" transform="translate(14.034889, 18.000000) scale(-1, -1) translate(-14.034889, -18.000000) " points="1.03488934 9 27.0348893 9 27.0348893 11.1066109 1.03488934 27"></polygon>
            <line x1="28.0348893" y1="3" x2="0.0348893373" y2="20" id="Path-2" stroke="#4B4A4B"></line>
            <line x1="28.0348893" y1="8" x2="0.0348893373" y2="25" id="Path-2" stroke="#4B4A4B"></line>
          </g>
        </g>
        <g id="handles" transform="translate(46.000000, 122.000000)">
            <g id="g1-handle1" transform="translate(0.000000, 171.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle7" transform="translate(275.000000, 171.000000)">
                <line x1="0.5" y1="1.5" x2="5.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="5.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle5" transform="translate(184.000000, 32.500000) rotate(-270.000000) translate(-184.000000, -32.500000) translate(181.000000, 31.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle4" transform="translate(184.000000, 19.500000) scale(1, -1) rotate(-270.000000) translate(-184.000000, -19.500000) translate(181.000000, 18.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle3" transform="translate(145.000000, 43.500000) rotate(-270.000000) translate(-145.000000, -43.500000) translate(142.000000, 42.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle2" transform="translate(145.000000, 24.500000) scale(1, -1) rotate(-270.000000) translate(-145.000000, -24.500000) translate(142.000000, 23.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
            <g id="g1-handle6" transform="translate(223.000000, 3.500000) scale(1, -1) rotate(-270.000000) translate(-223.000000, -3.500000) translate(220.000000, 2.000000)">
                <line x1="5.05435564e-13" y1="1.5" x2="4.49811656" y2="1.5" id="Path-3" stroke="#4B4A4B"></line>
                <circle id="Oval" fill="#4B4A4B" cx="4.5" cy="1.5" r="1.5"></circle>
            </g>
        </g>
        <g id="additional" transform="translate(63.000000, 173.000000)" fill="#FFFFFF" fillRule="nonzero" fontFamily="Univers" fontSize="12" fontWeight="normal">
            <g id="g1-sqlabel1b" transform="translate(0.000000, 87.000000)">
                <text id="0.9">
                    <tspan x="0.16" y="9.061">0.9</tspan>
                </text>
            </g>
            <g id="g1-sqlabel2b" transform="translate(40.000000, 37.000000)">
                <text id="0.7">
                    <tspan x="0.16" y="9.061">0.7</tspan>
                </text>
            </g>
            <g id="g1-sqlabel3b" transform="translate(79.000000, 0.000000)">
                <text id="0.4">
                    <tspan x="0.16" y="9.061">0.4</tspan>
                </text>
            </g>
            <g id="g1-sqlabel10b" transform="translate(236.000000, 61.061000)">
                <text id="2.6">
                    <tspan x="0.16" y="9">2.6</tspan>
                </text>
            </g>
        </g>
      </svg>
    </>
  )
}

export default Art1